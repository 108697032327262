import { useState, useEffect, useContext, useRef } from "react";
import api from "../../../service/rest/restApi";
import backend from "../../../service/rest/restService";
import { Button, Modal } from "antd";
import { useStreamSlice, useViewerLoadingSlice } from "../../../store";
import { AuthContext } from "../../../context/auth";
import { get } from "../../../utils/helpers";
import ProgressLoader from "../../Common/ProgressLoader";
import { useLocation } from "react-router-dom";
import { View } from "@millicast/sdk";
import  useHudsStatsStore  from "../../../store/slices/viewer/hudsStatsStore";

interface IProps {
  canvasRef: any;
  isReady: () => void;
  room_id:string;
  admin_user_id:string;
}

const ShowStream = (props: IProps) => {
  const { search } = useLocation();

  const params: any = new URLSearchParams(search);

  const user_id: string = params.get("uid");
  const room_id: string = params.get("rid");
  const room_name = sessionStorage.getItem('room_name') || '';
  const streamKeyRef = useRef(0);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
 
  const { streamKey, iceServers, showStreamModal, setShowStreamModal } = useStreamSlice();
  const { streamLoading, setStreamLoading } = useViewerLoadingSlice();
  const excludedElementTypes = ['input', 'textarea', 'select'];
  const { setLayerOptions,setHudsState } = useHudsStatsStore();

  useEffect(() => {
    const elem: any = document.getElementById("video-container");
    elem.addEventListener("fullscreenchange", fullscreenchanged);

    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;
      if (excludedElementTypes.includes(target.tagName.toLowerCase())) {
        return;
      }
      if (event.key === "f" || event.key === "F") {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          elem.requestFullscreen();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      elem.removeEventListener("fullscreenchange", fullscreenchanged);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const fullscreenchanged = () => {
    const videoElem: HTMLVideoElement = document.getElementById(
      "player"
    ) as HTMLVideoElement;
    if (
      document.fullscreenElement &&
      document.fullscreenElement.id === "video-container" &&
      videoElem
    ) {
      console.log("insdie if");
      videoElem.controls = true;
    } else {
      videoElem.removeAttribute("controls");
      videoElem.controls = false;
    }
  };

  useEffect(() => {
    // let pc: RTCPeerConnection;
    // let ws: WebSocket;
    // const script = document.createElement("script");
    // script.src = "https://webrtc.github.io/adapter/adapter-latest.js"; //May want to locally load or statically host on local site
    // script.async = true;
    // document.body.appendChild(script);
    props.isReady();

    const connect = async () => {
      try {
        setSuccessMessage("");
        setErrorMessage("");

        //Subscribe to Stream
        console.log("** Subscribe to stream");

        const input = {
          room_id: props.room_id,
          room_owner_user_id: props.admin_user_id,
          client_type: "web",
        };

        const data = {
          //api: api.streams.subscribeStream,
          api: api.room.subscribeStream,
          payLoad: JSON.stringify(input),
        };

        setStreamLoading(true);
        const result: any = await backend.save(data, true);
        setStreamLoading(false);

        if (!result || !result.jwt || !result.url) {
          setShowStreamModal(true);
          return;
        }
       
        if (result && result.jwt && result.url) {
          const videoElement = document.getElementsByTagName("video")[0];
          const tokenGenerator = async () => {
            return {
              wsUrl: result.url,
              urls: [result.url],
              jwt: result.jwt,
              iceServers: iceServers,
            };
          };

          const millicastView = new View(room_name, tokenGenerator, videoElement);
          
          millicastView.on("track", (event: any) => {
            
              videoElement.srcObject = event.streams[0];
              // setRefreshPlayer(!refreshPlayer)
            
           
          });
          millicastView
            .connect({
              events: [
                "active",
                "inactive",
                "stopped",
                "vad",
                "layers",
                "migrate",
                "viewercount",
              ],
            })
            .catch((e: any) => {
              console.log("Connection failed, handle error", e);
            });
            
          millicastView.on("reconnect", (err: any) => {
            console.log("Error connecting in", err.timeout);
            console.error(err.error);
          });

          const activeSources = new Set();

          millicastView.on("broadcastEvent", (event: any) => {
            const { name, data } = event;
            switch (name) {
              case "active":
                activeSources.add(data.sourceId);
                millicastView.webRTCPeer?.initStats();
                setHudsState({
                  activesStreamExists: true,
                  activesStreamStatus: true,
                });
                console.log("Active Stream.");
                break;
              case "inactive":
                activeSources.delete(data.sourceId);
                if (activeSources.size === 0) {
                  console.log("No active Stream.");
                }
                setHudsState({
                  activesStreamExists: false,
                  activesStreamStatus: false,
                });
                millicastView.webRTCPeer?.stopStats();
                break;
              case "layers":
                const layers = get(data, "medias.0.layers", []).map(
                  (layer: any) => ({
                    key: layer.encodingId,
                    value: layer,
                    text: layer.encodingId,
                  })
                );
                //TODO: If you need to enable the AUTO option, uncomment below the code
                layers.unshift({
                  key: "Auto",
                  value: {},
                  text: "Auto"
                })
                setLayerOptions(layers);
                break;
              default:
                break;
            }
          });
          
          
          setHudsState({
            milicastView: millicastView,
          });
          return () => {
            millicastView.stop();
          };
        }
        

      } catch (err: any) {
        let message = "Stream is NOT Live !";
        console.error(err);
        setStreamLoading(false);
        setShowStreamModal(true);
        const videoElement = document.getElementsByTagName("video")[0];
        videoElement.srcObject = null
        if (err && err.status === "fail") {
          message = err.data.message;
          console.error(message);
        }
      }
    };
      if(streamKeyRef.current !== streamKey){ 
        streamKeyRef.current = streamKey
        connect(); 
      }
  }, [streamKey,iceServers]);

  // useEffect(() => {
  //   const dominantSpeakerContainer: any = document.getElementsByClassName(
  //     "dominant-speaker-view"
  //   );

  //   if (!props.showDominantSpeaker && !!dominantSpeakerContainer) {
  //     while (
  //       !!dominantSpeakerContainer[0] &&
  //       dominantSpeakerContainer[0].firstChild
  //     ) {
  //       dominantSpeakerContainer[0].removeChild(
  //         dominantSpeakerContainer[0].lastChild
  //       );
  //     }
  //   }
  //   const elem: any = document.getElementsByTagName("video")[0];
  //   if (!!elem) {
  //     elem.muted = props.showDominantSpeaker ? true : props.volumeMute;
  //   }
  // }, [
  //   props.showDominantSpeaker,
  //   props.localAudioMuteValue,
  //   props.dominantSpeakerID,
  //   props.localParticpantTrack,
  //   props.dominantSpeakerTrack,
  //   props.showStreamUpdate,
  // ]);

  // const renderDominantSpeakerView = () => {
  //   // Get speaker div
  //   let dominantSpeakerDiv: any = null;
  //   let track: any = {};
  //   let tagName = "";

  //   if (props.showDominantSpeaker) {
  //     if (!!props.dominantSpeakerID) {
  //       dominantSpeakerDiv = document.getElementById(props.dominantSpeakerID);
  //       track = props.dominantSpeakerTrack;
  //     } else {
  //       dominantSpeakerDiv = document.getElementById(props.localParticipantID);
  //       track = props.localParticpantTrack;
  //     }
  //   }

  //   if (!!dominantSpeakerDiv) {
  //     const div = document.createElement("div");
  //     div.id = `${dominantSpeakerDiv.id}_clone`;
  //     // div.style.height = "100%";
  //     // div.style.width = "calc(100% - 60px)";
  //     div.setAttribute("class", "dominant-videoparticipant");

  //     let images = dominantSpeakerDiv.getElementsByTagName("img");

  //     if (images.length > 0) {
  //       const image = document.createElement("img");
  //       image.src = images[0].getAttribute("src");
  //       image.style.height = "100%";
  //       image.style.width = "100%";
  //       div.insertBefore(image, div.childNodes[0]);
  //       tagName = "IMAGE";
  //     } else {
  //       if (!!track) {
  //         div.appendChild(track.attach());
  //         tagName = "VIDEO";
  //       }
  //     }

  //     const dominantSpeakerContainer: any = document.getElementsByClassName(
  //       "dominant-speaker-view"
  //     );

  //     if (div.id !== dominantSpeakerContainer[0].childNodes[0]?.id) {
  //       while (dominantSpeakerContainer[0].firstChild) {
  //         dominantSpeakerContainer[0].removeChild(
  //           dominantSpeakerContainer[0].lastChild
  //         );
  //       }
  //       dominantSpeakerContainer[0].appendChild(div);
  //     } else {
  //       // if(tagName !== dominantSpeakerContainer[0]?.childNodes[0]?.childNodes[0]?.tagName){
  //       while (dominantSpeakerContainer[0]?.firstChild) {
  //         dominantSpeakerContainer[0].removeChild(
  //           dominantSpeakerContainer[0].lastChild
  //         );
  //       }
  //       dominantSpeakerContainer[0].appendChild(div);
  //       // }
  //     }
  //   }

  //   return null;
  // };

  // const getDominantSpeakerShowStatus = () => {
  //   if (props.showDominantSpeaker) {
  //     // if(!props.localAudioMuteValue) {
  //     //   return true;
  //     // } else if(!!props.dominantSpeakerID) {
  //     //   return true;
  //     // } else {
  //     //   return false;
  //     // }
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <div className="viewerstream">
      <div className="viewcontainer">
        <div
          id='video-container'
          className='video-container'
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <video
            className="viewerstream-video"
            id="player"
            width="100%"
            height="100%"
            controls={false}
            autoPlay
            disablePictureInPicture
            playsInline
          ></video>
          <canvas
            className="viewerstream-overlay"
            ref={props.canvasRef}
            id="canvas"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              zIndex: 1,
              top: "0",
              left: "0",
            }}
          ></canvas>
        </div>
      </div>
      {/* <ProgressLoader loading={streamLoading} size={'mini'} /> */}
      <Modal
        open={showStreamModal && !streamLoading}
        centered={true}
        onCancel={() => {
          setShowStreamModal(false);
        }}
        footer={[
          <Button
            type='primary'
            onClick={() => {
              setShowStreamModal(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div className='btn-close'>
          Stream is not currently running. Please reload the page once the publisher starts the
          stream.
        </div>
      </Modal>
    </div>
  );
};

export default ShowStream;
